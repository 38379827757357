/* global PAYUNI_SDK */
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Block, Text } from "../../Widgets";
import { ATM, CVS, Credit, Credits, LinePay, Offline } from "./PaymentMethods";
import { Context } from "../../AppContext";
import { message } from "antd";
import styled from "styled-components";
const constants = require("../../constants");

function Payment({ order, checkout, profile, onUpdate }) {
  const { calculations } = JSON.parse(order.cart);
  const app = useContext(Context);
  const [offlineModal, setOfflineModal] = useState(false);
  const [token, setToken] = useState(null);
  const [SDK, setSDK] = useState(null);
  const editOrderRemit = useCallback(
    async values => {
      await app.actions.editOrderRemit(order?.id, values);
      onUpdate();
    },
    [order, app.actions, onUpdate]
  );

  const initOption = {
    env: "P", // 預設 P 為正式環境，若要修改環境，請改為 S
    useHost: true, // 是否使用分層，若為店家開放分層請填寫true
    elements: {
      CardNo: "put_card_no", // 信用卡號碼
      CardExp: "put_card_exp", // 到期日
      CardCvc: "put_card_cvc", // 末三碼
      CardTokenType: "put_token_type",
    },
    style: {
      color: "#9b9b9b",
      errorColor: "#b93c3c",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: 2,
    },
  };

  useEffect(() => {
    if (
      order.display_state === "payment_waiting" &&
      order.payment_subtype === "credit"
    ) {
      const fetchToken = async () => {
        const token = await app.actions.getIframeToken(order.id);
        setToken(token);
      };
      fetchToken();
    }
  }, [order, app.actions]);

  useEffect(() => {
    if (token) {
      const SDK = PAYUNI_SDK.createSession(token, initOption);
      setSDK(SDK);

      const startProcess = async () => {
        try {
          await SDK.start();
        } catch (error) {
          console.log(error);
        }
      };
      startProcess();
    }
    return () => {
      console.log(PAYUNI_SDK);
    };
  }, [token]);

  const generatePaymentMethod = () => {
    const paymentTypes = {
      credits: "點數付款",
      offline: "臨櫃匯款",
      monthly: "月結付款",
    };

    const paymentSubtypes = {
      credit: "信用卡付款",
      cvs_cod: "超商取貨付款",
      cvs: "超商代碼繳費",
      atm: "ATM 轉帳付款",
      web_atm: "網路ATM付款",
      barcode: "超商條碼繳費",
      line_pay: "LINE PAY 付款",
      default: "自定義付款",
    };

    if (order.payment_type === "payuni" || order.payment_type === "neweb") {
      return paymentSubtypes[order.payment_subtype] || "未知付款方式";
    }

    return paymentTypes[order.payment_type] || "未知付款方式";
  };

  const renderPaymentContent = () => {
    const type =
      order.payment_type === "payuni"
        ? order.payment_subtype
        : order.payment_type;

    switch (type) {
      case "atm":
        return <ATM order={order} />;
      case "cvs":
        return <CVS order={order} />;
      case "line_pay":
        return <LinePay order={order} />;
      case "credits":
        return <Credits order={order} credits={profile.credits} />;
      case "credit":
        return <Credit />;
      case "offline":
        return (
          <Offline
            order={order}
            visible={offlineModal}
            values={{
              remittance_account: order.remittance_account,
              remittance_number: order.remittance_number,
              remitted_time: order.remitted_time,
            }}
            onCancel={() => setOfflineModal(false)}
            onEdit={editOrderRemit}
          />
        );
      case "default":
        return null;
      default:
        return null;
    }
  };

  const handleCreditOnSubmit = async () => {
    if (SDK) {
      try {
        let { Status, EncryptInfo } = await SDK.getTradeResult();
        if (Status === "SUCCESS") {
          message.success("付款中，請稍候");
          const result = await app.actions.getIframe3DUrl(EncryptInfo);
          window.open(result, "_self");
        }
      } catch (error) {
        message.error(error.message || "付款失敗，請聯絡客服人員");
      }
    }
  };

  const handleCheckoutOnClick = () => {
    if (order.payment_type === "offline") {
      setOfflineModal(true);
      return;
    }
    if (order.payment_subtype === "credit") {
      handleCreditOnSubmit();
      return;
    }
    checkout();
  };

  const shouldRenderCheckoutBtn = () => {
    if (order.payment_type === "offline") {
      return !(
        order.remittance_account ||
        order.remittance_number ||
        order.remitted_time
      );
    }

    if (order.payment_status !== "code_generated") {
      return true;
    }

    return false;
  };

  const Tag = () => {
    let wording = "未付款";
    if (order.payment_type === "offline") {
      const { remittance_account, remittance_number, remitted_time } = order;
      if (remittance_account && remittance_number && remitted_time) {
        wording = "待審核";
      }
    }
    if (order.payment_status === "failure") {
      wording = "付款失敗";
    }
    return (
      <StyledTag isRepay={order.payment_status === "failure"}>
        {wording}
      </StyledTag>
    );
  };

  const PaymentHeader = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "24px 24px 0",
      }}
    >
      {order.order_type === "extra" && "補收款 - "}
      {generatePaymentMethod()}
      <Tag />
    </div>
  );

  const CheckoutBtn = () => (
    <StyledButton
      disabled={
        order.payment_type === "credits" && order.amount > profile.credits
      }
      onClick={() => handleCheckoutOnClick()}
      isRepay={order.payment_status === "failure"}
    >
      {order.payment_status === "failure" ? "重新付款" : "前往付款"}
    </StyledButton>
  );

  return (
    <StyledBlock
      title={<PaymentHeader />}
      size={`${constants.fontSize.md}px`}
      fontWeight={700}
      height={"30px"}
      lineHeight={"30px"}
      padding={"0px"}
      wrapperPadding={"0"}
      wrapperPaddingMargin={"0 0 24px 0"}
      bg={constants.colors.background}
      color={constants.colors.text}
      css={{
        height: "auto",
      }}
    >
      <PaymentContent>{renderPaymentContent()}</PaymentContent>
      <PaymentFooter>
        <Text size="sm" weight="500" color="#000" css={{ marginRight: "8px" }}>
          總金額
        </Text>
        <Text size="lg" color="#000" weight="bold">
          NT$ {calculations.amount}
        </Text>
        {shouldRenderCheckoutBtn() && <CheckoutBtn />}
      </PaymentFooter>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const PaymentContent = styled.div`
  padding: 12px 0;
`;

const PaymentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
`;

const StyledButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  color: ${props =>
    props.disabled ? "#FF9D73" : props.isRepay ? "#FF9D73" : "white"};
  background-color: ${props =>
    props.disabled
      ? "#FCF0E9"
      : props.isRepay
      ? "#FFF4ED"
      : constants.buttons.background.normal.primary};
  border-radius: 12px;
  margin-left: 16px;
  transition: 0.3s all;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${props =>
      props.disabled
        ? "#FCF0E9"
        : props.isRepay
        ? "#FFF4ED"
        : constants.buttons.background.hover.primary};
  }
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    width: 96px;
    height: 44px;
  }
`;

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: ${props => (props.isRepay ? "#FEF6F6" : "#fff4ed")};
  color: ${props => (props.isRepay ? "#E2341D" : constants.colors.highlight)};
  font-size: 16px;
  font-weight: 500;
`;

export default Payment;
